
import {
  getPropertyAreaQuery,
  getPropertyCondosByBfeNumberQuery,
  getPropertyDlrDocuments,
  getPropertyDocumentsQuery,
} from "~/helpers/apollo/apollo-property-query-helpers.js";

import {
  createAnnualReportDownload,
  createBBRSheetDownload,
  createCondoBuildingDownload,
  createCorporateDiagramDownload,
  createEnergyLabelDownload,
  createOwnersOverviewDownload,
} from "~/helpers/download-helpers.js";

import ListItem from "../ListItem.vue";
import DownloadPopup from "../DownloadPopup.vue";
import Me from "~/graphql/Authentication/Me.gql";
import Keys from "~/graphql/Download/Keys.gql";
import DownloadQuery from "~/graphql/Download/Download.gql";
import Corporate from "~/components/Corporate.vue";
import DownloadDlrDocument from "~/graphql/Download/DownloadDlrDocument.gql";

export default {
  name: "Documents",
  components: {
    DownloadPopup,
    Corporate,
    ListItem,
  },
  props: {
    documents: {
      type: Object,
      required: true,
    },
    condos: {
      type: Array,
      required: true,
    },
    areas: {
      type: Object,
      required: true,
    },
    dlrDocuments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      items: {
        buildingTable: {
          value: false,
          handler: () => {
            if (this.documents.condo.unit == null) {
              return {};
            }
            return createCondoBuildingDownload([this.documents.condo.unit]);
          },
        },

        bbrSheet: {
          value: false,
          handler: () => {
            if (this.documents.condo.unit == null) {
              return {};
            }
            return createBBRSheetDownload(this.documents.bfeNumber, "BBR_SHEET");
          },
        },

        ownersOverview: {
          value: false,
          handler: () => createOwnersOverviewDownload(this.documents, this.condos, this.language),
        },
      },

      selectedDocTypes: {
        MORTGAGE: { selected: false },
        ACT: { selected: false },
        LIABILITIES: { selected: false },
        ENDORSEMENT_LIABILITIES: { selected: false },
        EASEMENTS: { selected: false },
        ENDORSEMENT_EASEMENTS: { selected: false },
        ENTITLEMENT: { selected: false },
        ATTEST: { selected: false },
      },

      energyLabels: {},
      corporateDiagrams: {},
      annualReports: {},
      localPlans: {},
      municipalityPlans: {},
      soilContaminations: {},
      modalOpen: false,
      documentsToDownload: [],
      language: "",
    };
  },
  apollo: {
    documents: getPropertyDocumentsQuery,
    condos: getPropertyCondosByBfeNumberQuery,
    areas: getPropertyAreaQuery,
    me: {
      query: Me,
    },
  },

  computed: {
    tinglysningListDocuments() {
      if (!this.dlrDocuments) {
        return new Map();
      }
      const grouped = Map.groupBy(this.dlrDocuments, ({ type }) => type);
      const sortedEntries = [...grouped.entries()]
        .sort((a, b) => b[0].localeCompare(a[0]))
        .map(([docType, docs]) => {
          const sortedDocs = [...docs].sort((a, b) => (a.name || "").localeCompare(b.name || ""));
          return [docType, sortedDocs];
        });
      return new Map(sortedEntries);
    },
    selected() {
      const selectedDocTypes = Object.entries(this.selectedDocTypes)
        .filter(([key, value]) => value.selected === true)
        .flatMap(([key, value]) => {
          return this.tinglysningListDocuments.get(key) || [];
        });
      const selectedAllItems = this.allItems.filter((item) => item && item.value === true);
      return [...selectedAllItems, ...selectedDocTypes];
    },

    allItems() {
      return [...Object.values(this.buildingDocuments), ...Object.values(this.ownersDocuments), ...Object.values(this.areasDocuments)];
    },

    dataIsLoading() {
      if (!this.$apolloData) {
        return true;
      }

      const value = this.$apolloData.queries.documents?.loading || this.$apolloData.queries.condos?.loading || this.$apolloData.queries.areas?.loading;

      // watching this computed property messed up everything. Temporary solution
      if (!value) {
        this.$nextTick(() => {
          this.populateEnergyLabels();
          this.populateAnnualReports();
          this.populateCorporateDiagrams();
        });
      }
      return value;
    },

    buildingDocuments() {
      if (this.documents.condo.unit == null) {
        return [];
      }
      return [this.items.buildingTable, this.items.bbrSheet, ...Object.values(this.energyLabels ?? {})];
    },

    buildingsMarkAll: {
      get() {
        return {
          val: this.buildingDocuments.every((x) => x.value === true),
          indeterminate: !this.buildingDocuments.every((x, i, a) => x.value === a[0].value),
        };
      },

      set(val) {
        this.buildingDocuments.forEach((x) => (x.value = val));
      },
    },

    ownersDocuments() {
      return [this.items.ownersOverview, ...Object.values(this.corporateDiagrams), ...Object.values(this.annualReports)];
    },

    ownersMarkAll: {
      get() {
        return {
          val: this.ownersDocuments.every((x) => x.value === true),
          indeterminate: !this.ownersDocuments.every((x, i, a) => x.value === a[0].value),
        };
      },

      set(val) {
        this.ownersDocuments.forEach((x) => (x.value = val));
      },
    },

    areasDocuments() {
      return [...Object.values(this.municipalityPlans ?? {}), ...Object.values(this.localPlans ?? {}), ...Object.values(this.soilContaminations ?? {})];
    },

    areasMarkAll: {
      get() {
        return {
          val: this.areasDocuments.every((x) => x.value === true),
          indeterminate: !this.areasDocuments.every((x, i, a) => x.value === a[0].value),
        };
      },
      set(val) {
        this.areasDocuments.forEach((x) => (x.value = val));
      },
    },

    address: function () {
      let address = this.documents.condo.unit?.address;
      if (address != null) {
        return `${address.streetName} ${address.streetNumber}, ${address.postalCode} ${address.city}`;
      } else {
        return this.$route.params.id.toString();
      }
    },
  },

  methods: {
    fetchAndOpenLink(document) {
      this.$apollo
        .query({
          query: DownloadDlrDocument,
          variables: {
            id: document.id,
            type: document.type,
            fileName: document.name,
          },
        })
        .then((response) => {
          if (response.data && response.data.downloadDlrDocument) {
            const baseUrl = window.location.origin;
            let filePath;
            if (baseUrl.includes("localhost") || baseUrl.includes("dev.estaid.dk")) {
              filePath = `https://files.dev.estaid.dk/downloads/${response.data.downloadDlrDocument}`;
            } else {
              filePath = `https://files.estaid.dk/downloads/${response.data.downloadDlrDocument}`;
            }
            window.open(filePath, "_blank");
          }
        })
        .catch((err) => {
          console.error("Error fetching the URL:", error);
        });
    },
    initiateDownload(selection) {
      this.modalOpen = true;
      this.documentsToDownload = selection;
    },

    initiateDownloadAll(selection) {
      this.setAll(true);
      this.initiateDownload(selection);
    },

    async download(selection, options) {
      this.language = options.selectedLocaleContentLanguage;
      let templateId = options.selectedLocale;

      const frontEndLinks = selection
        .filter((x) => !x.type)
        .map((x) => x.handler())
        .filter((link) => Object.keys(link).length !== 0);

      const backEndLinks = selection.filter((x) => x.type).map((x) => x.type);

      const resp = await this.$apollo.query({
        query: Keys,
        variables: {
          type: backEndLinks,
        },
      });

      //TODO: Jeppe - When we want to do templates in the frontend, use this
      // const mappedKeys = getMappedKeys(resp.data.keys, options.selectedLocale);

      const bfeNumber = this.$route.params.id;

      let download = {
        recipients: [],
        message: null,
        username: this.me.name,
        filename: "Estaid Download - " + bfeNumber.toString(),
        templateId: templateId,
        properties: [
          {
            bfeNumber: parseInt(bfeNumber, 10),
            address: this.address,
            downloadCondos: false,
            condoNumber: this.documents.condo?.condoNumber,
            selection: {
              includes: resp.data.keys.map((x) => x.type),
              links: await Promise.all(frontEndLinks),
            },
          },
        ],
      };

      if (options.reciever === "me" || options.getCopy) {
        download.recipients.push(this.me.email);
      }

      if (options.reciever === "other") {
        download.message = options.message;
        download.recipients.push(options.email);
      }

      this.$apollo
        .query({
          query: DownloadQuery,
          variables: { download: download },
        })
        .then(() => {
          this.modalOpen = false;
        })
        .catch(() => {
          this.modalOpen = false;
        });
    },

    setAll(val) {
      this.allItems.forEach((x) => {
        x.value = val;
      });
      Object.entries(this.selectedDocTypes).forEach(([key, value]) => {
        value.selected = val;
      });
    },

    handleDocTypeSelection(docType, value) {
      this.$set(this.selectedDocTypes, docType, value);
    },

    populateEnergyLabels() {
      this.energyLabels = this.documents?.energyLabels
        ?.sort((a, b) => a.buildingNumber - b.buildingNumber)
        .reduce((obj, label, i) => {
          obj[i] = {
            id: label.id,
            value: false,
            handler: () => createEnergyLabelDownload(label, this.language),
            data: label,
          };

          return obj;
        }, {});
    },

    populateAnnualReports() {
      const motherowners = this.documents.owners?.filter((x) => x && x.company);

      const companies =
        //remove duplicates
        motherowners?.filter((x, i, s) => s.findIndex((y) => y.cvrNumber === x.cvrNumber) === i).filter((x) => x.company.keyFigures?.length > 0);

      if (companies == null || companies.length === 0) {
        return;
      }

      this.annualReports = companies.reduce((obj, owner) => {
        obj[owner.cvrNumber] = {
          id: `annualreport_${owner.cvrNumber}`,
          value: false,
          handler: () => createAnnualReportDownload(owner, this.language),
          data: owner,
        };

        return obj;
      }, {});
    },

    populateCorporateDiagrams() {
      const motherowners = this.documents.owners?.filter((x) => x && x.company);
      const condoowners = this.condos.flatMap((x) => x.owners).filter((x) => x && x.company);

      const companies = [...(motherowners ?? []), ...(condoowners ?? [])]
        //remove duplicates
        .filter((x, i, s) => s.findIndex((y) => y.cvrNumber === x.cvrNumber) === i);

      if (companies == null || companies.length === 0) {
        return;
      }

      this.corporateDiagrams = companies.reduce((obj, owner) => {
        obj[owner.cvrNumber] = {
          id: `corporate_${owner.cvrNumber}`,
          value: false,
          handler: () => this.createCorporateDiagramDownload(owner, this.language),
          data: owner,
        };

        return obj;
      }, {});
    },

    createCorporateDiagramDownload(owner, language) {
      const canvas = document.querySelectorAll(`[id^='${owner.cvrNumber}']`)[0].firstElementChild.firstElementChild;
      const link = canvas.toDataURL().split(",")[1];

      return createCorporateDiagramDownload(link, owner, language);
    },
  },
};
